import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, FormControl, Link, TextField } from '@mui/material';
import axios from 'axios';

const theme = createTheme({
  palette: {
    mode: 'dark',
    main: '#d13030',
    contrastText: '#30d1d1',
    warningText: '#EF6C00'
  },
});

const handleFieldChange = (event, setFieldError, setFieldText) => {
  const fieldText = event.target.value;

  setFieldText(fieldText);

  const handle = fieldText.match(/@[^/]+/);
  const playlistId = fieldText.match(/(?<=playlist\?list\=)[^/\&]+/);
  const videoId  = fieldText.match(/(?<=watch\?v\=)[^/\&]+/);
  const shortVideoId = fieldText.match(/(?<=youtu\.be\/)[^/\&]+/);

  if(!handle && !playlistId && !videoId && !shortVideoId) {
    setFieldError(true);
  } else {
    setFieldError(false);
  }
};


const handleSubmit = (fieldText, setisSubmitted, setPredictions) => {
  setPredictions([]);

  console.log(fieldText);
  if(fieldText === '') {
    return;
  }


  setisSubmitted(true);

  const body = {
    "url": fieldText
  };

  const config = {
    "headers": {
      "content-type": "application/json"
    }
  };

  axios.post('https://9s8qnw2hce.execute-api.us-east-1.amazonaws.com/test', body, config)
    .then((res) => {
      console.log(res)
      setPredictions(res.data.predictions[0]);
      setisSubmitted(false);
    });
}

const PredictionDisplay = ({ predictions }) => {
  const h_prediction = Math.floor(predictions[0] * 100);
  const o_prediction = Math.floor(predictions[1] * 100);

  return (
    <Container>
      <Typography variant="h5" component="h5" align="center">
        This is { h_prediction }% similar to LGBT affirming sermons I've seen.
      </Typography>

      <Box sx={{ pt: 5 }} />

      <Typography variant="h5" component="h5" align="center">
        This is { o_prediction }% similar to orthodox sermons I've seen.
      </Typography>

      <Box sx={{ pt: 5}} />

      <Typography variant="body" component="explain" align="center" color={theme.palette.warningText}>
        <i>WARNING: This app is in alpha! These results are <b>not</b> reliable.
        At the moment, it only detects LGBT affirmation, and is not trained to find any other heresies.
        If this result seems incorrect, please email dev@paammar.org</i>
      </Typography>
    </Container>
  );
  // if(prediction !== -1) {
  //   if(prediction > 80) {
  //     return (
  //       <Typography variant="h5" component="h5" align="center">
  //         This is very likely heretical. The algorithm is { prediction }% sure that it fits heretical teaching better than orthodox teaching.
  //       </Typography>
  //     );
  //   } else {
  //     return (
  //       <Typography variant="h5" component="h5" align="center">
  //         This probably isn't heretical. The algorithm is only { prediction }% sure it's heretical, which makes it unlikely.
  //       </Typography>
  //     );
  //   }
  // } else {
  //   return;
  // }
};

export default function Home() {

  const [fieldText, setFieldText] = React.useState('');
  const [fieldError, setFieldError] = React.useState(false);
  const [isSubmitted, setisSubmitted] = React.useState(false);
  const [predictions, setPredictions] = React.useState([]);
  

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="header" sx={{ mt: 8, mb: 2 }} maxWidth="lg">
          <Typography variant="h1" component="h1" align="center">
            Heresy Detector
          </Typography>

          <Typography variant="overline" component="h1" align="center" gutterBottom>
            by @parkerforparker
          </Typography>
        </Container>

        <Box sx={{ pt: 10 }} />
        
        <Container component="instruct" maxWidth="md">
          <Typography variant="h5" component="h2">
            Start by entering the URL of a YouTube channel, playlist, or video below:
          </Typography>
        </Container>

        <Container component="fields" sx={{ mt: 6, mb: 2 }}  maxWidth="md">
          <FormControl fullWidth variant="standard">
          <TextField 
            error={ fieldError }
            helperText={fieldError ? "URL is not a YouTube channel, playlist, or video" : ""}
            onKeyDown={ (event) => { if(event.key === 'Enter' && !isSubmitted) { handleSubmit(fieldText, setisSubmitted, setPredictions) } } }
            onChange={ event => handleFieldChange(event, setFieldError, setFieldText) }
            id="outlined-basic"
            label="YouTube URL"
            variant="outlined"
          />
          </FormControl>
        </Container>

        {!isSubmitted && <Button onClick={ () => {handleSubmit(fieldText, setisSubmitted, setPredictions)}} gutterBottom>Submit</Button>}

        <Box sx={{ pt: 10 }}></Box>


        <Container component="results" sx={{ mt: 8, mb: 2 }} maxWidth="md">
          {isSubmitted && <LinearProgress />}

          {predictions.length !== 0 && <PredictionDisplay predictions={ predictions }></PredictionDisplay>}
        </Container>


        <Box sx={{ pt: 10 }}></Box>



        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) => theme.palette.main,
          }}
        >
          <Container maxWidth="md">
            <Typography variant="body1">
              This site was created for the sake of the Kingdom, please consider <Link href="/donate">donating</Link>.
            </Typography>
            <Typography variant="body1">Made by <Link href="https://x.com/@parkerforparker">@parkerforparker</Link></Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}