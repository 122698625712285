import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';




const theme = createTheme({
  palette: {
    mode: 'dark',
    main: '#d13030',
    contrastText: '#30d1d1',
  },
});


export default function Home() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="header" sx={{ mt: 8, mb: 2 }} maxWidth="lg">
          <Typography variant="h2" component="h1" align="center" gutterBottom>
            Thank you for considering a donation!
          </Typography>
        </Container>
        <Container component="exort" maxWidth="md">
          <Typography variant="h5" component="h2" gutterBottom>
            This site is the product of a great deal of effort on my part, and it's not free to operate, so I appreciate whatever you feel you can give very dearly.
          </Typography>
        </Container>

        <Box sx={{ pt: 10 }} />
        
        <Container component="donation" maxWidth="md">
          <Typography variant="body1" gutterBottom>
            At the moment all I have configured for donations is Bitcoin, my address is below:
          </Typography>
          <Typography variant="body1" color={theme.palette.contrastText} >bc1q0qjedwx0pget3pzrghjnspxelv25quv2n828rv</Typography>
        </Container>

          
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) => theme.palette.main,
          }}
        >
          <Container maxWidth="md">
            <Typography variant="body1">
              Made by @parkerforparker
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}